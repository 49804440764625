import React, { Component } from 'react';
import ProgressBar from 'react-animated-progress-bar'


class Resume extends Component {
  render() {

    if (this.props.data) {
      var skillmessage = this.props.data.skillmessage;
      var education = this.props.data.education.map(function (education) {
        return <div key={education.school}><h3>{education.school}</h3>
          <p className="info">{education.degree} <span>&bull;</span><em className="date">{education.graduated}</em></p>
          <p>{education.description}</p>

        </div>
      })
      var work = this.props.data.work.map(function (work) {
        return <div key={work.company}><h3>{work.company}</h3>
          <p className="info">{work.title}<span>&bull;</span> <em className="date">{work.years}</em></p>
          <p>{work.description}</p>
          {
            (work.title2) ? <div><p className="info">{work.title2}<span>&bull;</span> <em className="date">{work.years2}</em></p>
              <p>{work.description2}</p></div> : <div></div>
          }
          {
            (work.title3) ? <div><p className="info">{work.title3}<span>&bull;</span> <em className="date">{work.years3}</em></p>
              <p>{work.description3}</p></div> : <div></div>
          }
        </div>


      })

      var skills = this.props.data.skills.map(function (skills) {
        // var className = 'bar-expand '+skills.name.toLowerCase();
        // return <li key={skills.name}><span style={{width:skills.level}}className={className}></span><em>{skills.name}</em></li>
        return (
          <li key={skills.name}>
            <h5 style={{ marginBottom: '-15px' }}>{skills.name}</h5>
            <ProgressBar
              width="100%"
              height="22px"
              rect
              fontColor="gray"
              fontSize="16px"
              percentage={skills.level.slice(0, -1)}
              rectPadding="0px"
              rectBorderRadius="5px"
              trackPathColor="#cccccc"
              bgColor="#333333"
              trackBorderColor="white"
              defColor={{
                fair: 'orangered',
                good: 'yellow',
                excellent: '#313131',
                poor: 'red',
              }}
            />
          </li>
        )
      })
    }

    return (
      <section id="resume">

        <div className="row work">
          <div className="three columns header-col">
            <h1><span>Work</span></h1>
          </div>
          <div className="nine columns main-col">
            {work}
          </div>
        </div>

        <div className="row education">
          <div className="three columns header-col">
            <h1><span>Education</span></h1>
          </div>
          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">
                {education}
              </div>
            </div>
          </div>
        </div>

        <div className="row skill">
          <div className="three columns header-col">
            <h1><span>Skills</span></h1>
          </div>
          <div className="nine columns main-col">
            {/*<p>{skillmessage}*/}
            {/*</p>*/}
            {/* className="bars" */}
            <div >
              <ul className="skills">
                {skills}
              </ul>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Resume;
