import React, { Component } from 'react';
import emailjs from 'emailjs-com';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';

function Alert(props) {
   return <MuiAlert elevation={6} variant="filled" {...props} />;
}


class Contact extends Component {
   constructor(props) {
      super(props);
      this.state = {
         contactName: '',
         contactEmail: '',
         contactSubject: '',
         contactMessage: '',
         open: false,
         alertMessage: '',
         severity:'success'
      }
   }

    handleClick = (msg, severity) => {
      this.setState({
         alertMessage: msg,
         severity: severity,
         open: true
      })
    };
  
   handleClose = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      this.setState({
         open: false
      })
   };


   handleChange = (e) => {
      // console.log('e ', e.target.value);
      this.setState({
         [e.target.name]: e.target.value
      })
   }
   handleSubmitBtn = (e) => {
      e.preventDefault();
      // console.log('handleSubmitBtn');
      if (this.state.contactName !== '' && this.state.contactEmail !== '' && this.state.contactMessage !== '') {
         // console.log('can send message');
         emailjs.send('service_lkja1ii', 'template_ltfeu0b', { from_name: this.state.contactName + ' (' + this.state.contactEmail + ')', message: 'Subject: ' + this.state.contactSubject + ', Message: ' + this.state.contactMessage }, 'user_9wkN3HhcPkrDsvcY07uvg')
            .then((response) => {
               // console.log('SUCCESS!', response.status, response.text);
               this.setState({
                  contactName:'',
                  contactEmail:'',
                  contactSubject:'',
                  contactMessage:''
               });
               this.handleClick('Message Sent Successfully', 'success')
            }, (err) => {
               // console.log('FAILED...', err);
               this.handleClick('Failed to send message!', 'error')
            });
      }
      else {
         // show alert
         // console.log('Please input required fields');
         this.handleClick('Please input required fields', 'warning')
      }
   }
   render() {



      if (this.props.data) {
         var name = this.props.data.name;
         // var street = this.props.data.address.street;
         // var city = this.props.data.address.city;
         // var state = this.props.data.address.state;
         // var zip = this.props.data.address.zip;
         var phone = this.props.data.phone;
         var email = this.props.data.email;
         var message = this.props.data.contactmessage;
      }

      return (
         <>
         <div style={{width:'100%'}}>
         <Snackbar anchorOrigin={{horizontal:'center',vertical:'top'}} open={this.state.open} autoHideDuration={3000} onClose={this.handleClose}>
         <Alert onClose={this.handleClose} severity={this.state.severity} style={{fontSize:'14px'}}>
            {this.state.alertMessage}
         </Alert>
      </Snackbar>
      </div>
         <section id="contact">
           


            <div className="row section-head">

               <div id="customContactDiv" className="two columns header-col" style={{ display: 'flex', width: '100%', alignItems: 'center', marginBottom: '-15px' }}>

                  <h1 style={{ color: '#fff', fontSize: '22px' }}><span>Get In Touch</span></h1>
                  <h2 className="contacth2" style={{ color: '#fff', marginLeft: '15px' }}>GET IN TOUCH</h2>


               </div>


               <div className="ten columns">

                  <p className="lead">{message}</p>

               </div>

            </div>


            <div className="row">

               <div className="twelve columns">
                  {/* method="post" */}
                  <form action="" id="contactForm" name="contactForm">

                     <fieldset>


                        <div>
                           <label htmlFor="contactName">Your name <span className="required">*</span></label>
                           <input style={{ borderRadius: '10px' }} type="text" value={this.state.contactName} size="35" id="contactName" name="contactName" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactEmail">Your email <span className="required">*</span></label>
                           <input style={{ borderRadius: '10px' }} type="email" value={this.state.contactEmail} size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactSubject">Subject</label>
                           <input style={{ borderRadius: '10px' }} type="text" value={this.state.contactSubject} size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                           <textarea style={{ borderRadius: '10px' }} value={this.state.contactMessage} cols="50" rows="3" id="contactMessage" name="contactMessage" onChange={this.handleChange}></textarea>
                        </div>

                        <div>
                           <button style={{ border: '1px solid #fff', borderRadius: '10px' }} className="submit" onClick={this.handleSubmitBtn}>Submit</button>
                           {/* <span id="image-loader">
                        <img alt="" src="images/loader.gif" />
                     </span> */}
                        </div>
                     </fieldset>
                  </form>

                  <div id="message-warning"> Error boy</div>
                  <div id="message-success">
                     <i className="fa fa-check"></i>Your message was sent, thank you!<br />
                  </div>
               </div>



            </div>
         </section>
         </>
      );
   }
}

export default Contact;
